import React from 'react';
import { Link } from 'react-router-dom';
import './UserMenu.css';

const UserMenu = ({ onManageAccount, onManagePersonas }) => {
  return (
    <div className="user-menu">
      <button onClick={onManageAccount}>Manage Account</button>
      <button onClick={onManagePersonas}>Manage Personas</button>
      <Link to="/logout">Logout</Link>
    </div>
  );
};

export default UserMenu;