import React, { useState, useEffect } from 'react';
import { useUser } from '../UserContext';
import api from '../utils/api';
import AutocompleteInput from './AutocompleteInput';
import ParticipantBubble from './ParticipantBubble';
import './CreateChatModal.css';

const API_BASE_URL = "https://5h73bny644.execute-api.us-east-1.amazonaws.com/dev";

const CreateChatModal = ({ onClose, onChatCreated }) => {
  const { accountId, userId, username } = useUser(); // Assuming username is available in UserContext
  const [chatName, setChatName] = useState('');
  const [participants, setParticipants] = useState([
    { type: 'USER', id: userId, name: username } // Add current user by default
  ]);
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [personaSuggestions, setPersonaSuggestions] = useState([]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const usersResponse = await api.get(`${API_BASE_URL}/accounts/${accountId}/users`);
        const personasResponse = await api.get(`${API_BASE_URL}/accounts/${accountId}/personas`);
        
        const filteredUsers = usersResponse.data
          .filter(user => user.user_id !== userId) // Filter out current user from suggestions
          .map(user => ({ type: 'USER', id: user.user_id, name: user.username }));
        
        const filteredPersonas = personasResponse.data
          .map(persona => ({ type: 'PERSONA', id: persona.persona_id, name: persona.name }));
        
        setUserSuggestions(filteredUsers);
        setPersonaSuggestions(filteredPersonas);

        console.log('User suggestions:', filteredUsers);
        console.log('Persona suggestions:', filteredPersonas);
      } catch (error) {
        console.error('Failed to fetch suggestions', error);
      }
    };

    fetchSuggestions();
  }, [accountId, userId, username]);

  const handleAddParticipant = (participantName) => {
    const allSuggestions = [...userSuggestions, ...personaSuggestions];
    const participant = allSuggestions.find(s => s.name === participantName);
    if (participant && !participants.find(p => p.id === participant.id)) {
      setParticipants([...participants, participant]);
    }
  };

  const handleRemoveParticipant = (participantId) => {
    // Prevent removing the current user
    if (participantId !== userId) {
      setParticipants(participants.filter(p => p.id !== participantId));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post(`${API_BASE_URL}/accounts/${accountId}/users/${userId}/chats`, {
        chat_name: chatName,
        participants: participants.map(p => ({
          participant_type: p.type,
          participant_id: p.id
        }))
      });
      onChatCreated(response.data);
      onClose();
    } catch (error) {
      console.error('Failed to create chat', error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal create-chat-modal">
        <h2>Create New Chat</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Chat Name:
            <input
              type="text"
              value={chatName}
              onChange={(e) => setChatName(e.target.value)}
              required
            />
          </label>
          <label>
            Add Participants:
            <AutocompleteInput
              suggestions={[...userSuggestions, ...personaSuggestions].map(s => s.name)}
              onAddParticipant={handleAddParticipant}
            />
          </label>
          <div className="participants-list">
            {participants.map(participant => (
              <ParticipantBubble
                key={participant.id}
                name={participant.name}
                onRemove={() => handleRemoveParticipant(participant.id)}
                isCurrentUser={participant.id === userId}
              />
            ))}
          </div>
          <div className="modal-actions">
            <button type="submit">Create Chat</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateChatModal;