// src/utils/auth.js

export const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

export const saveTokens = (accessToken, refreshToken, expiresIn) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('tokenExpiresIn', expiresIn);
};

export const clearTokens = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('tokenExpiresIn');
};